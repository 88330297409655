import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";

function Main({children}) {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1,shrink-to-fit=no" />
                <title>Laden...</title>
            </Helmet>
            {children}
        </>
    );
}

Main.propTypes = {
    CommonStore: PropTypes.shape({
        title: PropTypes.string
    }),
    children: PropTypes.node
};

export default Main;