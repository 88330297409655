import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { inject, observer } from "mobx-react";
import { PropTypes as MobxPropTypes } from "mobx-react";
import FieldGroup from "components/FieldGroup/FieldGroup";
import { withRouter } from "react-router";
import { Button, Col, Form, Row } from "reactstrap";


function EmailForm({ configuratorStore: { maatregelen, calculationState, sendMail } }) {
    const { register, handleSubmit, errors } = useForm({
        defaultValue: calculationState
    });

    const onSubmit = values => sendMail(values);

    return <><Col xs="auto" className="mb-2 col-lg">
        <Form className="d-block" onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
                <Col xs="auto" className="mb-2 text-center text-md-left col-lg">
                <FieldGroup
                    className="mb-0"
                    name="email"
                    placeholder="email@voorbeeld.nl"
                    innerRef={register({
                        required: "required",
                        pattern: {
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            message: "Geen geldig email adres ingevuld"
                        }
                    })}
                    error={errors.email}
                    help_text={maatregelen.filter(maatregelen => maatregelen.saved === true).length === 0 && "Bewaar minimaal één maatregel om door te gaan"}
                />
            </Col>
            <Col xs="auto">
                <Button disabled={maatregelen.filter(maatregelen => maatregelen.saved === true).length === 0} color="transparent" className="btn btn-primary" type="submit">Verzend resultaten</Button>
            </Col>
        </Row>
    </Form>
    </Col>
    </>
}

EmailForm.propTypes = {
    configuratorStore: PropTypes.shape({
        bewaarde_maatregelen: PropTypes.arrayOf(PropTypes.object),
        error: PropTypes.string,
        success: PropTypes.string,
        activeTab: PropTypes.string,
        switchTab: PropTypes.func,
        sendMail: PropTypes.func,
        maatregelen: MobxPropTypes.observableArray,
        calculationState: PropTypes.object,
    })
};

export default withRouter(inject('configuratorStore')(observer(EmailForm)));