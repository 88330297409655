import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Collapse, CardBody, Card} from 'reactstrap';

function CollapseItem({answer, question}) {
    const [collapse, setCollapse] = useState(false);

    return (
        <div className="faq-item">
            <Collapse isOpen={collapse}>
                <Card className="border-bottom-0">
                    <CardBody className="bg-secondary" dangerouslySetInnerHTML={{__html: answer}}/>
                </Card>
            </Collapse>
            <div onClick={() => setCollapse(!collapse)} className="faq-item-head">
                {question}
            </div>

        </div>
    );
}

CollapseItem.propTypes = {
    question: PropTypes.string,
    answer: PropTypes.string
};

export default CollapseItem;