import React from 'react';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCalendarAlt, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, CardBody, CardTitle, CardText } from 'reactstrap';

function TimelineItemBlock({ timelineitem, iterator, theme }) {

    const convertDate = function (date) {
        var convertedDate = new Date(date);
        return convertedDate.toLocaleDateString('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' })
    }


    return (
        <li className="timeline-item" id={"timeline-wrapper" + iterator}>
            <span className="timeline-item-icon" id={"timeline-item-icon" + iterator}>
                <span className="fa-layers fa-fw fa-3x">
                    <FontAwesomeIcon inverse icon={faCircle} />
                    <FontAwesomeIcon className={'text-' + theme} icon={(timelineitem.completed ? faCalendarCheck : faCalendarAlt)} transform="shrink-6" />
                </span>
            </span>
            <div className="timeline-item-content px-lg-1">
                <Card>
                    <CardHeader>{convertDate(timelineitem.date)}</CardHeader>
                    <CardBody>
                        {timelineitem.title &&
                            <CardTitle><h4 className="text-secondary">{timelineitem.title}</h4></CardTitle>
                        }
                        <CardText className="text-primary">{timelineitem.text}</CardText>
                    </CardBody>
                </Card>
            </div>
        </li>

    );
}

TimelineItemBlock.propTypes = {
    timelineitem: PropTypes.shape({
        date: PropTypes.string,
        text: PropTypes.string,
        title: PropTypes.string,
        completed: PropTypes.bool
    }),
    iterator: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    theme: PropTypes.string
};
export default TimelineItemBlock;