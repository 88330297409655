import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "reactstrap";
import { withRouter } from "react-router";
import { Button } from "reactstrap";

function SearchForm({ isOpen, searchString, onSearch, history }) {
    const [search, changeSearch] = useState(searchString)
    function submitForm(submitEvent) {
        submitEvent.preventDefault();
        history.push('/search/' + search)
        onSearch(search)
    };

    return (
        <div id="site-search" className={isOpen ? 'open' : ''}>
            <form onSubmit={submitForm}>
                <Container >
                    <Row>
                        <Col className="flex-grow-1">
                            <input
                                ref={input => isOpen && input && input.focus()}
                                autoFocus
                                type="search"
                                className="form-control"
                                placeholder="Zoekterm"
                                value={search} onChange={(e) => changeSearch(e.target.value)}
                            />
                        </Col>
                        <Col className="col-auto site-search-button">
                            <Button color="light" outline type="submit">Zoeken</Button>
                        </Col>
                    </Row>
                </Container>
            </form>
        </div>
    )
}

SearchForm.propTypes = {
    isOpen: PropTypes.bool,
    searchString: PropTypes.string,
    onSearch: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func
    })
}

export default withRouter(SearchForm);