import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Col, Container, Row } from "reactstrap";
import withPage from "../../components/withPage/withPage";
import Blocks from "../../components/Blocks/Blocks";
import Footer from "../../components/Footer/Footer";


export class ProductPage extends Component {
    static propTypes = {
        PageStore: PropTypes.shape({
            details: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                title: PropTypes.string,
                body: PropTypes.object,
                footer: PropTypes.object,
                text_information: PropTypes.string
            })
        })
    };

    render() {

        const { PageStore: { details } } = this.props;

        return (
            details.body &&
            <>
                <main>
                    {details.title &&
                        <Row className="no-gutters pt-4">
                            <Col sm={12} className="mb-0 w-100">
                                <Container>
                                    <h1>{details.title}</h1>
                                </Container>
                            </Col>
                        </Row>
                    }
                    {details.text_information &&
                        <Row className="no-gutters pt-4">
                            <Col sm={12} className="mb-0 w-100">
                                <Container>
                                    <h2>Algemene informatie</h2>
                                    <div className="rte" dangerouslySetInnerHTML={{ __html: details.text_information }} />

                                </Container>
                            </Col>
                        </Row>
                    }


                    <Blocks blocks={details.body} />

                </main>

                <Footer
                    className='info-footer'
                    {...details.footer}
                />

            </>

        );
    }
}

export default withRouter(withPage((observer(ProductPage))))