import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row, Alert } from "reactstrap";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import FieldGroup from "components/FieldGroup/FieldGroup";
import HouseProperty from "./HouseProperty";
import PrevButton from "./PrevButton";


const houseProperties = [
    {
        name: "pandoppervlak",
        image: process.env.PUBLIC_URL + "/img/stepTwo/House1-01.png",
        label: "Dakoppervlak van woning",
        addon: "m²",
        tooltip_text: "step2_helptext_roof",
        register: { required: true }
    },
    {
        name: "opp_kruipruimte",
        image: process.env.PUBLIC_URL + "/img/stepTwo/House2-01.png",
        label: "Oppervlakte kruipruimte",
        addon: "m²",
        tooltip_text: "step2_helptext_basement",
        register: {}
    },
    {
        name: "plat_dakoppervlak",
        image: process.env.PUBLIC_URL + "/img/stepTwo/House4-01.png",
        label: "Oppervlakte plat dak",
        addon: "m²",
        tooltip_text: "step2_helptext_flatroof",
        register: { required: true }
    },
    {
        name: "tuinoppervlak",
        image: process.env.PUBLIC_URL + "/img/stepTwo/House3-01.png",
        label: "Oppervlakte tuin",
        addon: "m²",
        tooltip_text: "step2_helptext_garden",
        register: { required: true }
    },
    {
        name: "verhard_tuinoppervlak",
        image: process.env.PUBLIC_URL + "/img/stepTwo/House6-01.png",
        label: "Verharding tuin",
        addon: "m²",
        tooltip_text: "step2_helptext_pavement",
        register: {}
    },
    {
        name: "schuttinglengte",
        image: process.env.PUBLIC_URL + "/img/stepTwo/House5-01.png",
        label: "Lengte schutting",
        addon: "m",
        tooltip_text: "step2_helptext_fence",
        register: {}
    }
];

function ValidationForm({ details, history, configuratorStore: { calculationState, runCalculations, goToTab, activeTab, error } }) {
    if (calculationState.pandoppervlak === null && process.env.NODE_ENV !== 'development') {
        goToPreviousTab();
    } else {
        goToTab("2")
    }

    const { register, handleSubmit, errors, setError } = useForm({
        defaultValue: calculationState
    });

    function goToPreviousTab() {
        history.push(`/${details.meta.slug}`)
    }

    const onSubmit = data => {
        if (parseFloat(data.verhard_tuinoppervlak) > parseFloat(data.tuinoppervlak)) {
            return setError("verhard_tuinoppervlak", {
                type: "manual",
                message: 'Verhard tuinoppervlak mag niet groter zijn dan de oppervlakte van de tuin.'
            });
        }
        for (const property in data) {
            data[property] = parseFloat(data[property]);
        }
        runCalculations(data).then(() => {
            history.push(`/${details.meta.slug}/resultaten`)
        })
    }

    return (
        <Form
            className="form-horizontal"
            onSubmit={handleSubmit(onSubmit)}
        >
            {calculationState.aantal_adressen > 1 &&
                <Alert color="warning"><div className="rte mb-0" dangerouslySetInnerHTML={{ __html: details.step2_multiple_house_single_lot }} /></Alert>
            }
            <Row>
                {houseProperties.map((houseProperty, index) => <HouseProperty
                    key={index}
                    image={houseProperty.image}
                    label={houseProperty.label}
                >
                    <FieldGroup
                        className="form-control text-right  border-primary"
                        {...houseProperty}
                        defaultValue={calculationState[houseProperty.name]}
                        error={errors[houseProperty.name]}
                        innerRef={register(houseProperty.register)}
                        type="number"
                        step="any"
                        min="0"
                        tooltip_text={details[houseProperty.tooltip_text]}
                    />
                </HouseProperty>)}

                {error.message && <div className="alert alert-danger">
                    {error.message}
                </div>}
            </Row>
            <Row className="mt-3 mb-3">
                <Col className="text-center">
                    <PrevButton
                        toggle={goToPreviousTab}
                        activeTab={activeTab}
                        buttonText={details.step2_button_previous}
                    />
                    <Button color="transparent" className="btn btn-primary"
                        type="submit">{details.step2_button_next}</Button>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4 mb-3">
                <Col sm={12}>
                    <div className="alert alert-info">
                        <div className="rte" dangerouslySetInnerHTML={{ __html: details.step2_data_origin }} />
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

ValidationForm.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }),
    details: PropTypes.object,
    configuratorStore: PropTypes.shape({
        calculationState: PropTypes.object,
        runCalculations: PropTypes.func,
        goToTab: PropTypes.func,
        switchTab: PropTypes.func,
        activeTab: PropTypes.string,
        error: PropTypes.string
    })
};

export default withRouter(inject('configuratorStore')(observer(ValidationForm)));