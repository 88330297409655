import React from "react";
import PropTypes from "prop-types";
import AddressForm from "components/Configurator/AddressForm";
import Header from "components/Configurator/Header";

function StepOne({ details }) {
    return <>
        <Header title={details.step1_intro_head}
            content={details.step1_intro_text} />
        <AddressForm details={details} />
    </>;
}

StepOne.propTypes = {
    details: PropTypes.shape({
        step1_intro_text: PropTypes.string,
        step1_intro_head: PropTypes.string
    })
}

export default StepOne;