import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup, ListGroupItem, CardTitle, CardText } from 'reactstrap';
import { faPlus, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

function Features(content) {


    return (
        <div color="white" className="card-features p-4 border bg-light">
            <CardTitle className="text-primary d-flex justify-content-between">
                <h4 className="mb-0">
                    {content.data.title}
                </h4>
            </CardTitle>
            <CardText className="text-primary" >{content.data.subtitle}</CardText>

            <ListGroup className="border-0">
                {content.data.items.map((item, index) => (

                    <ListGroupItem key={index} className="d-flex align-items-center text-primary">
                        {item.icon === 'faPlus' &&
                            <FontAwesomeIcon color="red" className="text-info card-features-icon mr-2" icon={faPlus} />
                        }
                        {item.icon === 'faCheck' &&
                            <FontAwesomeIcon color="red" className="text-success card-features-icon mr-2" icon={faCheck} />
                        }
                        {item.icon === 'faTimes' &&
                            <FontAwesomeIcon color="red" className="text-danger card-features-icon mr-2" icon={faTimes} />
                        }
                        <span className="h5 mb-0">{item.description}</span>
                    </ListGroupItem>
                ))}

            </ListGroup>
            {content.data.hyperlink_intern &&
                <Link className="btn btn-primary" to={content.data.hyperlink_intern.meta.slug} >{content.data.hyperlink_intern.title}</Link>
            }
        </div>
    );
}

Features.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.shape({
            text: PropTypes.string,
            block_theme: {
                select_theme: PropTypes.string
            },
            margin_bottom: {
                select_margin_bottom: PropTypes.string
            }
        })
    })
};
export default Features;