import { action, computed, decorate, observable, runInAction } from "mobx";
import api from "services/api";
import { pick } from "lodash";


export class ConfiguratorStore {
    calculationState = {
        postcode: "",
        huisnummer: "",
        toevoeging: undefined,
        straatnaam: "",
        woonplaats: "",
        privacy: false,

        pandoppervlak: null,
        tuinoppervlak: null,
        verhard_tuinoppervlak: null,
        plat_dakoppervlak: null,
        schuttinglengte: null,
        has_kruipruimte: false,
        opp_kruipruimte: null,
        balkonoppervlak: null,
        infiltreren: null,
        grondwaterstand: null,
        wateroverlast_label: null,
        overstroming_kans_label: null
    };
    maatregelen = []
    berging = null;
    bergingseis = null;
    activeTab = "1"
    loading = false;
    error = "";
    success = "";

    goToTab = (tab) => {
        if (this.activeTab !== tab) {
            this.activeTab = tab
            //scroll to top
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
    }

    switchTab = (tab) => {
        if (tab >= this.activeTab && process.env.NODE_ENV !== 'development') return;
        this.goToTab(tab);
    }

    get bewaarde_maatregelen() {
        return this.maatregelen.filter(maatregel => maatregel.saved)
    }

    getAddress = async ({ huisnummer, toevoeging }) => {
        if (this.error) throw Error(this.error);
        runInAction(() => {
            this.error = ""
            this.loading = true;
        })

        try {
            const data = {
                perceelid: this.calculationState.perceelid,
                huisnummer: parseInt(huisnummer, 10),
                toevoeging: toevoeging || undefined
            }

            let response = await api.Address.getPerceel(data)

            runInAction(() => {
                this.loading = false;
                this.calculationState = {
                    ...this.calculationState,
                    ...response
                }
            })
        } catch (error) {
            runInAction(() => {
                this.loading = false;
                this.error.message = error.response.data.message
            })
        }
    }

    autoCompleteAddress = async ({ postcode, huisnummer, toevoeging }) => {
        if (!postcode || !huisnummer) return;

        runInAction(() => {
            this.error = ""
            this.loading = true;
        })

        try {
            const data = {
                postcode: postcode.replace(/[\s-]/g, "").toUpperCase(),
                huisnummer: parseInt(huisnummer, 10),
                toevoeging: typeof toevoeging === 'string' ? toevoeging : undefined
            };
            let response = await api.Address.find(data)

            runInAction(() => {
                this.loading = false;
                this.calculationState.postcode = postcode;
                this.calculationState.huisnummer = huisnummer;
                this.calculationState.straatnaam = "";
                this.calculationState.woonplaats = "";

                if (response.length === 1 && response[0]) {
                    this.calculationState = {
                        ...this.calculationState,
                        ...response[0]
                    }
                    if (!response[0].toevoeging) {
                        this.calculationState.toevoegingOptions = undefined;
                    }
                } else if (response.length > 1) {
                    this.calculationState = {
                        ...this.calculationState,
                        ...response[0]
                    }
                    this.calculationState.toevoegingOptions = response.map(adresOption => adresOption.toevoeging)
                } else {
                    this.calculationState.toevoegingOptions = undefined;
                    this.error = "Adres niet gevonden";
                }
            })
        } catch (error) {
            runInAction(() => {
                this.loading = false;
                this.error.message = error.response.data.message
            })
        }
    };

    saveMeasure = (id) => {
        this.maatregelen = this.maatregelen.map(maatregel => {
            if (maatregel.id !== id) return maatregel;

            maatregel.saved = !maatregel.saved;
            return maatregel
        })
    };

    runCalculations = async (data) => {
        runInAction(() => {
            this.error = ""
            this.loading = true;
        })

        if (data.opp_kruipruimte >= 0) {
            data.has_kruipruimte = true;
        } else {
            data.has_kruipruimte = false;
        }

        data.infiltreren = this.calculationState.infiltreren;
        data.grondwaterstand = this.calculationState.grondwaterstand;

        try {
            let response = await api.Calculate.run(data)
            let maatregelen = await api.Page.getProducts();

            runInAction(() => {
                this.loading = false;
                this.berging = response.berging;
                this.bergingseis = response.bergingseis;
                this.calculationState = {
                    ...this.calculationState,
                    ...data
                }
                this.maatregelen = maatregelen.items.filter(maatregel => {
                    const maatregelVariant = response.maatregelen.find(variant => variant.id === maatregel.measure_id && variant.website_aantal > 0)
                    if (!maatregelVariant) return false;

                    maatregel.variants = maatregel.variants.find(variant => variant.label === maatregelVariant.maatregelen)
                    Object.assign(maatregel.variants, maatregelVariant)

                    return true;
                })
            })
        } catch (error) {
            runInAction(() => {
                this.loading = false;
                this.error.message = error.response.data.message
            })
        }
    };

    sendMail = async ({ email }) => {
        runInAction(() => {
            this.error = ""
            this.loading = true;
        })


        const labelValues = ['A', 'B', 'C', 'D', 'E', 'F']

        const propertiesToSend = [
            'pandoppervlak',
            'tuinoppervlak',
            'verhard_tuinoppervlak',
            'plat_dakoppervlak',
            'schuttinglengte',
            'has_kruipruimte',
            'opp_kruipruimte',
            'balkonoppervlak',
            'infiltreren',
            'grondwaterstand'
        ]

        const data = {
            email: email,
            measures: this.bewaarde_maatregelen,
            data: {
                ...pick(this.calculationState, propertiesToSend),
                wateroverlast_label: labelValues[parseInt(this.calculationState.wateroverlast_label - 1)],
                overstroming_kans_label: labelValues[parseInt(this.calculationState.overstroming_kans_label - 1)],
                berging: this.berging,
                bergingseis: this.bergingseis
            }
        }

        try {
            await api.Email.send(data)

            runInAction(() => {
                this.loading = false;
                this.success = "Email is succesvol verstuurd"
            })
        } catch (error) {
            runInAction(() => {
                this.loading = false;
                this.error.message = error.response.data.message
            })
        }
    };
}

decorate(ConfiguratorStore, {
    activeTab: observable,
    calculationState: observable,
    error: observable,
    success: observable,
    maatregelen: observable,
    berging: observable,
    bergingseis: observable,
    loading: observable,
    bewaarde_maatregelen: computed,
    goToTab: action,
    switchTab: action,
    getAddress: action,
    saveMeasure: action,
    runCalculations: action,
    sendMail: action
})

export default new ConfiguratorStore();