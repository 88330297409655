import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer, inject } from "mobx-react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, Container, Row, Col } from "reactstrap";
import Loader from "components/Loader/Loader";
import Footer from "components/Footer/Footer";
import withPage from "../../components/withPage/withPage";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";


const tabData = [
    {
        id: 1,
        header: "1",
        path: "",
        component: StepOne
    },
    {
        id: 2,
        header: "2",
        path: "/perceel",
        component: StepTwo
    }
    ,
    {
        id: 3,
        header: "3",
        path: "/resultaten",
        component: StepThree
    }
];

export class ConfiguratorPage extends Component {
    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func
        }),
        match: PropTypes.shape({
            path: PropTypes.string
        }),
        PageStore: PropTypes.shape({
            details: PropTypes.shape({
                meta: PropTypes.shape({
                    slug: PropTypes.string
                }),
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                title: PropTypes.string,
                body: PropTypes.object,
                footer: PropTypes.object
            })
        }),
        configuratorStore: PropTypes.shape({
            activeTab: PropTypes.string,
            switchTab: PropTypes.func,
            loading: PropTypes.bool,
            goToTab: PropTypes.func
        })
    };

    render() {

        const { PageStore: { details }, match, history, configuratorStore: { switchTab, activeTab, loading } } = this.props;
        function switchTabAndGoToUrl(tab) {
            switchTab(tab.header);
            history.push(`/${details.meta.slug}${tab.path}`)
        }
        return (
            details.body &&
            <>
                <div className="pt-4">
                    <Container className="configurator">
                        {loading &&
                            <Loader />
                        }

                        <Row>
                            <Col>
                                <Nav data-active-step={activeTab}
                                    className="status-nav d-flex justify-content-between py-2 mb-4" tabs>
                                    {tabData.map(tab => <NavItem key={tab.id}>
                                        <NavLink
                                            className={" " + (activeTab >= tab.header ? 'bg-primary text-white border-white' : ' ')}
                                            onClick={() => switchTabAndGoToUrl(tab)}
                                        >
                                            {tab.header}
                                        </NavLink>
                                    </NavItem>)}
                                </Nav>

                                <TabContent activeTab={activeTab} >
                                    <Switch>
                                        {tabData.map(tab => {
                                            let Component = tab.component;
                                            return <Route key={tab.id} exact path={`${match.path}${tab.path}`} >
                                                <Component details={details} />
                                            </Route>
                                        })}
                                    </Switch>
                                </TabContent>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer
                    className='info-footer'
                    {...details.footer}
                />
            </>

        );
    }
}

export default withRouter(withPage(inject('PageStore', 'configuratorStore')(observer(ConfiguratorPage))))