import React, { useState } from "react";
import { Col, Row, Modal, ModalHeader, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import { isNotEmptyHTML, replaceParameterTags } from "utils/textHelpers";

function SolutionCard({ solution, saveMeasure, open = false, data = {} }) {
    const [modal, setModal] = useState(open)

    function toggleModal(e) {
        e.preventDefault();
        setModal(!modal);
    };

    const variant = solution.variants;

    const showHighlightText = variant.website_aantal && solution.highlight_text;

    const images = solution.image_gallery && solution.image_gallery.map((image, index) =>
        <Row key={index} className="mb-4">
            <Col>
                <figure>
                    <img className="img-fluid" alt={image.caption} src={image.large.url} />
                    {image.caption &&
                        <figcaption>{image.caption}</figcaption>
                    }
                </figure>
            </Col>
        </Row>
    );

    return (
        <Col md={6} className="mb-3">
            <div className={`card ${showHighlightText && 'highlight'}`}>
                {showHighlightText && <span className="highlight-text">{solution.highlight_text}</span>}
                <a className="card-img-link" href="?"
                    onClick={toggleModal}>
                    {solution.image_gallery[0] && <figure>
                        <img className="card-img-top" src={solution.image_gallery[0].large.url} title={solution.image_gallery[0].caption} alt={solution.image_gallery[0].caption} />
                        {solution.image_gallery[0].caption &&
                            <figcaption>{solution.image_gallery[0].caption}</figcaption>
                        }
                    </figure>}
                </a>
                <div className="card-body  text-dark bg-light">
                    <Row className="align-items-center card-body-top">
                        <Col xs={12}>
                            <h3 className="card-title h4 mb-3">{solution.title}</h3>
                            <div className="rte" dangerouslySetInnerHTML={{ __html: solution.text_information }}></div>
                        </Col>
                    </Row>
                    <Row className="align-items-center mb-3">
                        <Col xs={12} sm={4} md={12} lg={4}>
                            {solution.illustration &&
                                <img className="card-illustration mx-auto d-block "
                                    src={solution.illustration.url}
                                    alt={solution.title} />
                            }
                        </Col>
                        <Col xs={12} sm={8} md={12} lg={8}
                            className="text-center text-sm-right text-md-center text-lg-right">

                            {variant.website_aantal ? (
                                //configurator
                                <dl className="row no-gutters">
                                    <dt className="col-8 pt-2 pb-2 font-weight-normal">Aantal</dt>
                                    <dd className="col-4 mb-0 pt-2 pb-2 pl-2">{variant.website_aantal} {solution.price_unit}</dd>
                                    <dt className="col-8 border-top border-thick pt-2 pb-2 font-weight-normal">Totaal mogelijk</dt>
                                    <dd className="col-4 border-top border-thick mb-0 pt-2 pb-2 pl-2">{variant.website_aantal * variant.berging} liter</dd>
                                    <dt className="col-8 border-top border-thick pt-2 pb-2 font-weight-normal">Kosten vanaf</dt>
                                    <dd className="col-4 border-top border-thick mb-0 pt-2 pb-2 pl-2">&euro; {variant.website_aantal * variant.kosten}</dd>
                                </dl>
                            ) : (
                                <>
                                    {/* //maatregelpagina */}
                                    <small className="clearfix">Kosten vanaf {solution.price_unit && '(per ' + solution.price_unit+')'}</small>
                                    <span className="price text-nowrap">&euro; {solution.price} </span>
                                </>
                            )}

                        </Col>
                    </Row>
                    <Row className="align-items-center justify-content-between no-gutters ">
                        <Col xs={12} sm="auto" md={12} lg="auto"
                            className="text-center mt-3 text-sm-left text-md-center text-lg-left">
                            {saveMeasure &&
                                <button
                                    onClick={() => saveMeasure(solution.id)}
                                    type="submit"
                                    color="secondary"
                                    className={"btn  " + (solution.saved ? 'btn-success' : 'btn-secondary')}
                                >
                                    <FontAwesomeIcon className="mr-2" icon={solution.saved ? faCheck : faPlus} />

                                    {solution.saved ? 'Bewaard' : 'Bewaar maatregel'}
                                </button>
                            }

                        </Col>
                        <Col xs={12} sm="auto" md={12} lg="auto"
                            className="text-center  mt-3 text-sm-right text-md-center text-lg-right">
                            <button onClick={toggleModal}
                                className="btn btn-primary">
                                Meer informatie
                            </button>

                        </Col>
                    </Row>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggleModal} size="lg" className="modal-configurator">
                <ModalHeader toggle={toggleModal}>{solution.title}</ModalHeader>
                <ModalBody>
                    <Row>
                        {variant.website_aantal &&
                            <>
                                <Col xs={12} lg={6}>
                                    <h3>Details</h3>
                                    <dl className="row no-gutters">
                                        <dt className="col-8 border-top border-thick pt-2 pb-2 font-weight-normal">Aantal</dt>
                                        <dd className="col-4 border-top border-thick mb-0 pt-2 pb-2 pl-2">{variant.website_aantal} {solution.price_unit}</dd>
                                        <dt className="col-8 border-top border-thick pt-2 pb-2 font-weight-normal">Totaal mogelijk</dt>
                                        <dd className="col-4 border-top border-thick mb-0 pt-2 pb-2 pl-2">{variant.website_aantal * variant.berging} liter</dd>
                                        <dt className="col-8 border-top border-thick pt-2 pb-2 font-weight-normal">Kosten vanaf</dt>
                                        <dd className="col-4 border-top border-thick mb-0 pt-2 pb-2 pl-2">&euro; {variant.website_aantal * variant.kosten}</dd>
                                    </dl>
                                </Col>
                                <Col xs={12} lg={6} className="text-right">
                                    <button
                                        onClick={() => saveMeasure(solution.id)}
                                        type="submit"
                                        color="secondary"
                                        className={"btn  " + (solution.saved ? 'btn-success' : 'btn-secondary')}
                                    >
                                        <FontAwesomeIcon className="mr-2" icon={solution.saved ? faCheck : faPlus} />

                                        {solution.saved ? 'Bewaard' : 'Bewaar maatregel'}

                                    </button>
                                </Col>
                            </>

                        }
                    </Row>
                    <Row>
                        <Col xs={12}>

                            <Col xs={12} md={6} className="float-md-right">
                                {images}
                            </Col>
                            {isNotEmptyHTML(solution.text_information) &&
                                <>
                                    <h3>Algemene informatie</h3>
                                    <div className="rte" dangerouslySetInnerHTML={{ __html: solution.text_information }}></div>
                                </>
                            }
                            {isNotEmptyHTML(solution.text_how_it_works) &&
                                <>

                                    <h3>Hoe werkt het</h3>
                                    <div className="rte" dangerouslySetInnerHTML={{ __html: solution.text_how_it_works }}></div>
                                </>
                            }
                            {(isNotEmptyHTML(solution.text_results) && variant.website_aantal) &&
                                <div className="rte" dangerouslySetInnerHTML={{ __html: replaceParameterTags(solution.text_results, { ...data, ...variant }) }}></div>
                            }
                            {isNotEmptyHTML(solution.text_maintainance) &&
                                <>
                                    <h3>Onderhoud</h3>
                                    <div className="rte" dangerouslySetInnerHTML={{ __html: solution.text_maintainance }}></div>
                                </>
                            }
                            {isNotEmptyHTML(solution.text_subsidy) &&
                                <>
                                    <h3>Subsidies</h3>
                                    <div className="rte" dangerouslySetInnerHTML={{ __html: solution.text_subsidy }}></div>
                                </>
                            }
                            {isNotEmptyHTML(solution.text_installation) &&
                                <>
                                    <h3>Installatie</h3>
                                    <div className="rte" dangerouslySetInnerHTML={{ __html: solution.text_installation }}></div>
                                </>
                            }
                        </Col>






                    </Row>
                    {!variant.website_aantal && solution.price &&
                        // //maatregelpagina
                        <Row className="align-items-center">

                            <Col className="text-left">
                                <span className="h3 text-nowrap">Kosten vanaf: </span>
                            </Col>
                            <Col className="text-right">

                                <span className="h3 text-nowrap">&euro; {solution.price} {solution.price_unit && 'per ' + solution.price_unit}</span>

                            </Col>
                        </Row>
                    }


                </ModalBody>

            </Modal>

        </Col>
    )
}

SolutionCard.propTypes = {
    solution: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        price: PropTypes.number,
        price_unit: PropTypes.string,
        saved: PropTypes.bool,
        highlight_text: PropTypes.string,
        text_information: PropTypes.string,
        text_how_it_works: PropTypes.string,
        text_maintainance: PropTypes.string,
        text_results: PropTypes.string,
        text_subsidy: PropTypes.string,
        text_installation: PropTypes.string,
        image_gallery: PropTypes.object,
        illustration: PropTypes.object,
        variants: MobxPropTypes.observableArrayOf(PropTypes.shape({
            website_aantal: PropTypes.number,
            costs: PropTypes.number,
            kosten: PropTypes.number,
            berging: PropTypes.number
        }))
    }),
    data: PropTypes.object,
    saveMeasure: PropTypes.func,
    open: PropTypes.bool
};

export default SolutionCard;