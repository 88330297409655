/* eslint-disable no-console */
import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";


class Offline extends Component {

    constructor(props) {
        super(props);
        this.handleConnectionChange = this.handleConnectionChange.bind(this);
        this.state = {  connection: navigator.onLine };
    }

    handleConnectionChange(event){

        console.log(event.type);

        if(event.type === "offline"){
            console.log("You lost connection.");

            this.setState({
                connection: false
            });
        }
        if(event.type === "online"){
            console.log("You are now back online.");

            this.setState({
                connection: true
            });
        }
    }
    
    render() {
        window.addEventListener('online', this.handleConnectionChange);
        window.addEventListener('offline', this.handleConnectionChange);
    
        return (
            <>

                {!this.state.connection && 
                    <div className="navbar fixed-bottom bg-danger text-white border-top border-white" >
                        <p className="w-100 text-center mb-0">
                        
                        <FontAwesomeIcon  className="mr-1 py-0" icon={faExclamationTriangle} />
                        U bent offline, 

                        u kijkt mogelijk naar verouderde informatie. Open de site wanneer u online bent voor de meest actuele informatie.
                        </p>
                    </div>
                }
            </>

        );
    }
}

export default Offline;