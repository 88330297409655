import { Alert, Spinner } from "reactstrap";
import React from "react";

const Loader = () => <div className="page-loader">
    <Alert color="info" className="page-loader-alert">
        <Spinner color="primary" className="mr-2" />
        <span className="text-secondary h3 mb-0">Laden...</span>
    </Alert>
</div>;

export default Loader