import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Input, Jumbotron, Label, Row } from "reactstrap";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router";
import houseImg from "assets/img/houseproperties/house.png";
import FieldGroup from "components/FieldGroup/FieldGroup";
import ErrorFeedback from "./ErrorFeedback";

function AddressForm({ details, history, configuratorStore: { calculationState, autoCompleteAddress, getAddress, goToTab, error } }) {
    const { register, handleSubmit, errors, getValues, formState } = useForm({ mode: 'onBlur' });

    goToTab("1");

    const onSubmit = data => {
        if (!formState.isValid) return;
        getAddress(data).then(() => {
            history.push(`/${details.meta.slug}/perceel`)
        }).catch(err => false)
    };

    function onBlur() {
        autoCompleteAddress(getValues());
    }

    return (
        <Form
            className="form-horizontal"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Row className="justify-content-center">
                <Col sm={12} lg={8}>
                    <Jumbotron>
                        <Row className="align-items-top mb-lg-3">

                            <Col lg={3}>
                                <img className="img-fluid img-house mx-auto d-block" alt="house" src={houseImg} />
                            </Col>

                            <Col>
                                <FieldGroup
                                    name="postcode"
                                    label="Postcode"
                                    placeholder="Postcode"
                                    autoComplete="postal-code"
                                    defaultValue={calculationState.postcode}
                                    innerRef={register({ required: true, pattern: /^[1-9][0-9]{3} ?[A-Za-z]{2}$/i })}
                                    error={errors.postcode}
                                    onBlur={onBlur}
                                />
                            </Col>

                            <Col>
                                <FieldGroup
                                    name="huisnummer"
                                    label="Huisnummer"
                                    placeholder="Huisnummer"
                                    autoComplete="address-level3"
                                    defaultValue={calculationState.huisnummer}
                                    error={errors.huisnummer}
                                    type="number"
                                    min="0"
                                    innerRef={register({ required: true, min: { value: 0, message: "Huisnummer moet hoger dan 0 zijn" } })}
                                    onBlur={onBlur}
                                />
                            </Col>
                            <Col>

                                <FieldGroup
                                    name="toevoeging"
                                    label="Toevoeging"
                                    placeholder="Toevoeging"
                                    autoComplete="address-level4"
                                    type="select"
                                    disabled={!calculationState.toevoegingOptions}
                                    defaultValue={calculationState.toevoeging}
                                    error={errors.toevoeging}
                                    help_text={calculationState.toevoegingOptions && 'Meerdere toevoegingen gevonden'}
                                    onBlur={onBlur}
                                >
                                    {calculationState.toevoegingOptions && calculationState.toevoegingOptions.map(toevoeging => <option key={toevoeging}>{toevoeging}</option>)}
                                </FieldGroup>
                            </Col>
                        </Row>
                        <FieldGroup
                            name="straatnaam"
                            label="Straatnaam"
                            placeholder="Straatnaam"
                            value={calculationState.straatnaam}
                            disabled
                        />
                        <FieldGroup
                            name="woonplaats"
                            label="Woonplaats"
                            placeholder="Woonplaats"
                            value={calculationState.woonplaats}
                            disabled
                        />
                        {error && <div className="alert alert-danger">
                            {error}
                        </div>}
                    </Jumbotron>
                    {calculationState.straatnaam && <FormGroup check>
                        <Input
                            innerRef={register({ required: true })}
                            id="privacy"
                            name="privacy"
                            type="checkbox"
                            invalid={!!errors.privacy}
                        />{' '}
                        <Label for="privacy" check>
                            Ja, ik geef toestemming om de door mij ingevulde informatie te gebruiken voor een op maat gemaakt digitaal advies.
                        </Label>
                        {errors.privacy && <ErrorFeedback error={errors.privacy} />}
                    </FormGroup>}
                    {details.step1_privacy_url &&
                        <div className="text-right">
                            <small><a title="privacyvoorwaarden" href={details.step1_privacy_url.file} target="_blank" rel="noopener noreferrer">{details.step1_privacy_statement}</a></small>
                        </div>
                    }
                </Col>
            </Row>
            <Row className="mt-3 mb-3">
                <Col className="text-center">
                    <Button color="transparent" className="btn btn-primary" type="submit">{details.step1_button_next}</Button>
                </Col>
            </Row>
        </Form >
    );
}

AddressForm.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }),
    details: PropTypes.shape({
        meta: PropTypes.shape({
            slug: PropTypes.string
        }),
        step1_privacy_url: PropTypes.shape({
            file: PropTypes.string
        }),
        step1_checkbox_text: PropTypes.string,
        step1_privacy_statement: PropTypes.string,
        step1_button_next: PropTypes.string
    }),
    configuratorStore: PropTypes.shape({
        getAddress: PropTypes.func.isRequired,
        autoCompleteAddress: PropTypes.func,
        calculationState: PropTypes.object,
        goToTab: PropTypes.func,
        error: PropTypes.string
    })
};

export default withRouter(inject('configuratorStore')(observer(AddressForm)))