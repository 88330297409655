//import "core-js/stable";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import { Provider as MobxProvider } from 'mobx-react';
import 'mobx-react/batchingForReactDom'
import { configure } from "mobx";
import TagManager from 'react-gtm-module'

import App from './App';
import * as serviceWorker from './serviceWorker';
import stores from './stores/stores';

configure({ enforceActions: "observed" });


const tagManagerArgs = {
    gtmId: 'GTM-T2FTWXW'
}

TagManager.initialize(tagManagerArgs)


ReactDOM.render(
    <MobxProvider {...stores}>
        <App />
    </MobxProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
