import PageStore from "./Page/PageStore";
import CommonStore from "./Common/CommonStore"
import userStore from "./User/UserStore";
import authStore from "./Auth/AuthStore";
import searchStore from "./Search/SearchStore";
import configuratorStore from "./Configurator/ConfiguratorStore";
import contactStore from "./Contact/ContactStore";

const stores = {
    PageStore,
    CommonStore,
    userStore,
    authStore,
    searchStore,
    configuratorStore,
    contactStore
};

export default stores;