import axios from 'axios'
import Cookies from 'js-cookie';
import commonStore from 'stores/Common/CommonStore';
import authStore from 'stores/Auth/AuthStore';
import { history } from 'App';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const WAGTAIL_API_ROOT = "/api";
const API_ROOT = "https://c2012-0101-api.azurewebsites.net"
const config = {
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
};

axios.interceptors.request.use(function (config) {
    if (commonStore.token && config.url.indexOf(WAGTAIL_API_ROOT) !== -1) {
        config.headers.Authorization = `Token  ${commonStore.token}`;
    }

    return config;
});

axios.interceptors.response.use(undefined, error => {
    switch (error.response.status) {
        case 401:
            authStore.logout()
            history.push('/login')
            break;
        case 403:
            history.push('/')
            break;
        default:
            break;
    }
    return Promise.reject(error);
});

const requests = {
    get: url => axios.get(`${WAGTAIL_API_ROOT}${url}`).then(res => res.data),
    post: (url, data) =>
        axios.post(`${WAGTAIL_API_ROOT}${url}`, data, config).then(res => res.data),
    put: (url, data) =>
        axios.put(`${WAGTAIL_API_ROOT}${url}`, data, config).then(res => res.data),
    delete: url => axios.delete(`${WAGTAIL_API_ROOT}${url}`).then(res => res.status)
};

const Auth = {
    login: (data) => requests.post('/auth/', data),
    current: () => requests.get('/user/')
}

const Page = {
    /**
     * Get all pages
     * @return Promise
     */
    all: () => requests.get('/pages/?fields=*&limit=1000'),
    /**
     * Get page details by id
     * @param id Number
     * @return Promise
     */
    byId: (id) => requests.get(`/pages/${id}/`),
    meta: (slug) => requests.get(`/pages/?slug=${slug}&fields=*&limit=1000`),

    getProducts: (parent_id) => requests.get(`/pages/?type=product.ProductPage&fields=*&limit=1000${parent_id ? '&child_of=' + parent_id : ''}`),
    /**
     * Get products on configurator page
     * @param id Number
     * @return Promise
     */
};

const Search = {
    /**
     * Get searchresults on a page
     * @param search String
     * @return Promise
     */
    getSearchresults: (search) => requests.get(`/pages/?search=${search}&fields=*`),
}

const Address = {
    find: (data) => axios.post(`${API_ROOT}/adres`, data, config).then(res => res.data),
    getPerceel: (data) => axios.post(`${API_ROOT}/perceelgegevens`, data, config).then(res => res.data)
};


const Calculate = {
    run: (data) => axios.post(`${API_ROOT}/maatregelen`, data, config).then(res => res.data),
};

const Email = {
    send: (data) => requests.post("/email/", data)
};

const Contact = {
    send: (data) => requests.post("/contact/", data)
}

export default {
    Auth,
    Page,
    Search,
    Address,
    Calculate,
    Email,
    Contact
}