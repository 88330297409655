import React from "react";
import PropTypes from "prop-types";
import Header from "components/Configurator/Header";
import ValidationForm from "components/Configurator/ValidationForm";

function StepTwo({ details }) {
    return <>
        <Header title={details.step2_intro_head}
            content={details.step2_intro_text} />

        <ValidationForm details={details} />
    </>;
}

StepTwo.propTypes = {
    details: PropTypes.shape({
        step2_intro_head: PropTypes.string,
        step2_intro_text: PropTypes.string,
        step2_data_origin: PropTypes.string
    })
};

export default StepTwo;