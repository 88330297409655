import React, { Component } from "react";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import PropTypes from "prop-types";
import { Col, Container, Row, Card, CardBody, CardTitle } from "reactstrap";
import SearchForm from "components/SearchForm/SearchForm";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

export class SearchPage extends Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                search: PropTypes.string
            })
        }),
        searchStore: PropTypes.shape({
            count: PropTypes.number,
            searchresults: MobxPropTypes.observableArrayOf(PropTypes.shape({
                id: PropTypes.number
            })),

            getSearchresults: PropTypes.func,
            /**
             * loadingSearchresults state
             */
            loading: PropTypes.bool,

            searchString: PropTypes.string
        }),
        PageStore: PropTypes.shape({
            getPathWithParent: PropTypes.func
        })
    };

    componentDidMount() {
        this.props.searchStore.getSearchresults(this.props.match.params.search);
    }

    componentDidUpdate() {
        const search = this.props.match.params.search;
        if (search !== this.props.searchStore.searchString) {
            this.props.searchStore.getSearchresults(search)
        }
    }

    render() {
        const {
            searchStore: {
                loading,
                searchresults,
                count,
                getSearchresults,
                searchString
            },
            PageStore: {
                getPathWithParent
            },
            match: { params: { search } }
        } = this.props;
        return (
            <>
                <Helmet>
                    <title>Zoeken</title>
                </Helmet>
                <main>
                    <Container>

                        <Row className="justify-content-center pt-4">
                            <Col xs="12" lg="8">
                                <div className="searchresults-header mb-2">
                                    {searchString &&
                                        <h1>U zocht op <i>{searchString}</i> </h1>
                                    }

                                    {!loading && searchresults &&
                                        <p>Er zijn {count} resultaten</p>
                                    }
                                </div>
                            </Col>
                            <Col xs="12" lg="8">
                                <div className="searchresults-form mb-3">
                                    <SearchForm key={search} isOpen onSearch={getSearchresults} searchString={search} />
                                </div>
                            </Col>

                            <Col xs="12" lg="8">
                                <div className="searchresults-list">
                                    {!loading && searchresults &&
                                        searchresults.map((searchresult, index) => {
                                            const url = getPathWithParent(searchresult);
                                            let breadCrumbArray = url.split(/\/|#/)
                                            if (url.includes('#')) {
                                                breadCrumbArray.pop()
                                            }
                                            breadCrumbArray.shift();
                                            return <Link key={index} className="searchresults-list-item" to={`${url}?search=${searchString}`}>
                                                <Card className="mb-3">
                                                    <CardBody>
                                                        <CardTitle>
                                                            {breadCrumbArray.length > 1 && <small>{breadCrumbArray.join(' > ')}</small>}
                                                            <h5>{searchresult.title}</h5>
                                                        </CardTitle>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </>
        );
    }
}
export default inject('searchStore', 'PageStore')(observer(SearchPage));