import React from "react";
import { Col, Row } from "reactstrap";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import Header from "components/Configurator/Header";
import SolutionCard from "components/Configurator/SolutionCard";
import PrevButton from "components/Configurator/PrevButton";
import EmailForm from "components/Configurator/EmailForm";
import Waterlabel from "components/Configurator/Waterlabel";
import { withRouter } from "react-router";
import { replaceParameterTags } from "utils/textHelpers";


function StepThree({ history, configuratorStore: { calculationState, maatregelen, goToTab, activeTab, saveMeasure, error, success, ...data }, details }) {
  if (!maatregelen.length && process.env.NODE_ENV !== 'development') {
    goToPreviousTab();
  } else {
    goToTab("3")
  }

  calculationState.onverhard_tuinoppervlak = calculationState.tuinoppervlak - calculationState.verhard_tuinoppervlak;

  const solutions = maatregelen.filter(solution => solution != null)
    .map((solution) =>
      <SolutionCard
        key={solution.id}
        saveMeasure={saveMeasure}
        solution={solution}
        data={calculationState}
      />
    );



  function goToPreviousTab() {
    history.push(`/${details.meta.slug}/perceel`)
  }

  return solutions.length < 1 ? <>
    <div className="alert alert-warning" role="alert" dangerouslySetInnerHTML={{ __html: details.step3_no_results_text }} />
    <Row className="mt-3 mb-3">
      <Col xs="12" className="text-center">
        <PrevButton
          activeTab={activeTab}
          toggle={goToPreviousTab}
          buttonText={details.step3_button_previous}
        />
      </Col>
    </Row>
  </> : <>
    <Header title={details.step3_intro_head}
      content={details.step3_intro_text}
      {...data}
    />
    <Row>
      <Waterlabel
        head={details.wateroverlast_head}
        intro={details.wateroverlast_intro}
        text={details.wateroverlast_text}
        labels={[details.wateroverlast_a, details.wateroverlast_b, details.wateroverlast_c, details.wateroverlast_d, details.wateroverlast_e, details.wateroverlast_f]}
        hyperlink_moreinfo={details.wateroverlast_hyperlink_moreinfo}
        hyperlink_measures={details.wateroverlast_hyperlink_measures}
        label={calculationState.wateroverlast_label}
        {...data}
      />
      <Waterlabel
        head={details.overstroming_head}
        intro={details.overstroming_intro}
        text={details.overstroming_text}
        labels={[details.overstroming_a, details.overstroming_b, details.overstroming_c, details.overstroming_d, details.overstroming_e, details.overstroming_f]}
        hyperlink_moreinfo={details.overstroming_hyperlink_moreinfo}
        hyperlink_measures={details.overstroming_hyperlink_measures}
        label={calculationState.overstroming_kans_label}
        {...data}
      />
    </Row>
    {details.step3_intro_berging &&
      <Row>
        <Col sm={12} >
          <div className="alert alert-info mb-4" dangerouslySetInnerHTML={{ __html: replaceParameterTags(details.step3_intro_berging, data) }} />
        </Col>

      </Row>
    }

    <Row>
      {solutions}
    </Row>

    <Row className="mt-3 mb-3 justify-content-center">
      {details.step3_email_more_information &&
        <Col xs="12" dangerouslySetInnerHTML={{ __html: details.step3_email_more_information }} />

      }
      <Col xs="12">

        {error && <div className="alert alert-danger w-100">
          {error}
        </div>}
        {success && <div className="alert alert-success w-100">
          {success}
        </div>}

      </Col>
      <Col xs="auto" className="text-center mb-4 mb-lg-0">
        <PrevButton
          activeTab={activeTab}
          toggle={goToPreviousTab}
          buttonText={details.step3_button_previous}
        />
      </Col>
      <EmailForm />
    </Row>
    {details.step3_disclaimer &&
      <Row className="justify-content-center mb-3">
        <Col sm={12}>
          <div className="alert alert-info">
            <small dangerouslySetInnerHTML={{ __html: details.step3_disclaimer }} />
          </div>
        </Col>
      </Row>
    }
  </>;
}

StepThree.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  configuratorStore: PropTypes.shape({
    maatregelen: MobxPropTypes.observableArray,
    totaal: PropTypes.number,
    goToTab: PropTypes.func,
    activeTab: PropTypes.string,
    switchTab: PropTypes.func,
    saveMeasure: PropTypes.func,
    calculationState: PropTypes.object,
    error: PropTypes.string,
    success: PropTypes.string,
  }),
  details: PropTypes.shape({
    meta: PropTypes.shape({
      slug: PropTypes.string
    }),
    step3_no_results_text: PropTypes.string,
    step3_button_next: PropTypes.string,
    step3_button_previous: PropTypes.string,
    step3_disclaimer: PropTypes.string,
    step3_intro_berging: PropTypes.string,
    step3_intro_text: PropTypes.string,
    step3_intro_head: PropTypes.string,
    step3_email_more_information: PropTypes.string,
  })
};

export default withRouter(inject('configuratorStore')(observer(StepThree)));
