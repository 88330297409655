import React from "react";
import PropTypes from "prop-types";

const getViewBox = name => {
  switch (name) {
    case "Facebook":
      return "0 0 40 40";
    case "Instagram":
      return "0 0 40 40";
    case "linkedin":
      return "0 0 40 40";
    case "twitter":
      return "0 0 40 40";
    case "vimeo":
      return "0 0 40 40";
    case "youtube":
      return "-28 -28 130 130";
    case "bubble":
      return "0 0 40 40";
    case "bulb":
      return "0 0 40 40";
    case "layers":
      return "0 0 16 16";
    case "questionmark":
      return "0 0 40 40";
    case "search":
      return "0 0 26 26";
    default:
      return "0 0 40 40";
  }
};

const getPath = (name, props) => {
  switch (name) {
    case "facebook":
      return (
        <path
          {...props}
          d="M21.437 15.937v-2.025c0-.304.014-.54.043-.706.028-.167.092-.33.192-.492.1-.162.262-.274.485-.335.224-.062.521-.093.892-.093h2.025v-4.05h-3.237c-1.874 0-3.22.444-4.037 1.333-.818.889-1.226 2.198-1.226 3.93v2.438h-2.425v4.05h2.424V31.74h4.864V19.988h3.238l.428-4.05h-3.666z"
        />
      );
    case "instagram":
      return (
        <path
          {...props}
          d="M29.2 8.5H10.8a2.307 2.307 0 0 0-2.3 2.3v18.4c0 1.265 1.035 2.3 2.3 2.3h18.4c1.265 0 2.3-1.035 2.3-2.3V10.8c0-1.265-1.035-2.3-2.3-2.3zM20 15.4c2.53 0 4.6 2.07 4.6 4.6s-2.07 4.6-4.6 4.6-4.6-2.07-4.6-4.6 2.07-4.6 4.6-4.6zm-8.625 13.8c-.345 0-.575-.23-.575-.575V18.85h2.415c-.115.345-.115.805-.115 1.15 0 3.795 3.105 6.9 6.9 6.9 3.795 0 6.9-3.105 6.9-6.9 0-.345 0-.805-.115-1.15H29.2v9.775c0 .345-.23.575-.575.575h-17.25zM29.2 13.675c0 .345-.23.575-.575.575h-2.3c-.345 0-.575-.23-.575-.575v-2.3c0-.345.23-.575.575-.575h2.3c.345 0 .575.23.575.575v2.3z"
        />
      );
    case "linkedin":
      return (
        <path
          {...props}
          d="M30.542 21.333v8.247h-4.78v-7.695c0-1.933-.692-3.252-2.423-3.252-1.321 0-2.107.89-2.453 1.75-.126.307-.159.734-.159 1.165v8.032h-4.782s.064-13.032 0-14.383h4.782v2.04l-.032.046h.032v-.047c.635-.979 1.77-2.377 4.31-2.377 3.147 0 5.505 2.056 5.505 6.474zm-19.6-13.069c-1.637 0-2.707 1.073-2.707 2.485 0 1.38 1.04 2.486 2.643 2.486h.032c1.668 0 2.705-1.106 2.705-2.486-.031-1.412-1.037-2.485-2.673-2.485zM8.519 29.58H13.3V15.197H8.52V29.58z"
        />
      );
    case "twitter":
      return (
        <path
          {...props}
          d="M32.941 14.043a9.64 9.64 0 0 1-2.771.76 4.847 4.847 0 0 0 2.121-2.668 9.73 9.73 0 0 1-3.067 1.171 4.827 4.827 0 0 0-8.225 4.4 13.704 13.704 0 0 1-9.948-5.042 4.79 4.79 0 0 0-.653 2.426c0 1.675.853 3.152 2.147 4.017a4.827 4.827 0 0 1-2.186-.606v.06a4.832 4.832 0 0 0 3.871 4.733 4.89 4.89 0 0 1-1.272.17c-.312 0-.613-.032-.908-.09a4.83 4.83 0 0 0 4.508 3.352 9.684 9.684 0 0 1-5.995 2.063c-.39 0-.773-.024-1.151-.066a13.644 13.644 0 0 0 7.398 2.171c8.88 0 13.733-7.354 13.733-13.732l-.016-.625a9.639 9.639 0 0 0 2.414-2.494z"
        />
      );
    case "vimeo":
      return (
        <path
          {...props}
          d="M27.96 10.254c-3.127-.102-5.244 1.653-6.352 5.266a4.36 4.36 0 0 1 1.666-.354c1.141 0 1.646.64 1.511 1.924-.067.777-.57 1.909-1.51 3.393-.944 1.487-1.65 2.229-2.117 2.229-.608 0-1.162-1.149-1.667-3.443-.17-.676-.472-2.397-.906-5.167-.407-2.565-1.481-3.764-3.229-3.596-.74.068-1.847.745-3.325 2.027-1.078.979-2.17 1.959-3.281 2.938l1.058 1.365c1.01-.709 1.6-1.062 1.765-1.062.774 0 1.497 1.215 2.17 3.644.603 2.23 1.211 4.458 1.817 6.686.903 2.43 2.016 3.646 3.325 3.646 2.117 0 4.71-1.991 7.766-5.975 2.958-3.814 4.485-6.819 4.588-9.015.135-2.936-.957-4.44-3.278-4.506z"
        />
      );
    case "youtube":
      return (
        <path
          {...props}
          d="M59.6,11.8H16.4C7.9,11.8,1,18.7,1,27.2v21.6c0,8.5,6.9,15.4,15.4,15.4h43.3c8.5,0,15.4-6.9,15.4-15.4V27.2    C75,18.7,68.1,11.8,59.6,11.8z M49.2,39.1L29,48.7c-0.5,0.3-1.2-0.1-1.2-0.7V28.1c0-0.6,0.6-1,1.2-0.7l20.2,10.3    C49.9,37.9,49.8,38.8,49.2,39.1z"
        />
      );

    case "bubble":
      return (
        <path
          {...props}
          d="m38.265055,18.829016c0,-7.407542 -8.06805,-13.412638 -18.021211,-13.412638c-9.952737,0 -18.769245,6.005095 -18.769245,13.412638c0,7.407966 8.909536,13.413484 18.220811,13.413484l0,4.232398l6.337846,-4.920162c7.162825,-1.773967 12.231799,-6.798246 12.231799,-12.725719z"
        />
      );
    case "bulb":
      return (
        <path
          {...props}
          d="M26.789 13.22c0-6.49-5.261-11.75-11.75-11.75S3.289 6.73 3.289 13.22c0 1.594.321 3.112.896 4.5h-.004l.01.016a11.722 11.722 0 0 0 1.665 2.802l4.266 7.181h11l3.669-7.945a11.69 11.69 0 0 0 1.998-6.555zM15.481 38.72c2.96 0 5.36-2.687 5.36-7H10.12c0 4.313 2.4 7 5.36 7zM8.272 12.97a7.209 7.209 0 0 1 7.21-7.21"
        />
      );
    case "layers":
      return (
        <path
          {...props}

          d="M 8,1.9960938 7.7636719,2.125 0.5,6.0390625 8,10.080078 15.5,6.0390625 z M 1.5429688,7.5527344 0.5,8.1171875 8,12.169922 15.5,8.1171875 14.457031,7.5527344 8,11.041016 z m 0,2.1445312 L 0.5,10.261719 8,14.314453 15.5,10.261719 14.457031,9.6972656 8,13.185547 z"
        />
      );

    case "questionmark":
      return (
        <path
          {...props}

          d="M 21.75 1.398438 C 18.585938 1.054688 15.429688 2.054688 13.101562 4.140625 C 10.8125 6.195312 9.5 9.132812 9.5 12.203125 L 15.007812 12.203125 C 15.007812 10.671875 15.640625 9.265625 16.78125 8.242188 C 17.96875 7.179688 19.523438 6.695312 21.15625 6.875 C 23.679688 7.148438 25.710938 9.164062 25.984375 11.648438 C 26.210938 13.65625 25.3125 15.609375 23.648438 16.75 C 19.960938 19.273438 17.765625 23.390625 17.765625 27.757812 L 23.273438 27.757812 C 23.273438 25.203125 24.578125 22.789062 26.765625 21.289062 C 30.117188 18.992188 31.914062 15.0625 31.46875 11.03125 C 30.898438 6.007812 26.8125 1.953125 21.75 1.398438 Z M 21.75 1.398438 M 23.609375 34.789062 C 23.609375 36.503906 22.222656 37.890625 20.507812 37.890625 C 18.792969 37.890625 17.40625 36.503906 17.40625 34.789062 C 17.40625 33.074219 18.792969 31.6875 20.507812 31.6875 C 22.222656 31.6875 23.609375 33.074219 23.609375 34.789062 Z M 23.609375 34.789062"
        />
      );
      case "search":
      return (
        <path
          {...props}

          d="M25.1 23.2l-6.2-6.3c1.6-1.9 2.5-4.2 2.5-6.6C21.3 4.6 16.7 0 10.9 0S.4 4.6.4 10.3s4.7 10.3 10.5 10.3c2.2 0 4.2-.6 6-1.9l6.2 6.3c.3.3.6.4 1 .4s.7-.1.9-.4c.6-.4.6-1.3.1-1.8zM10.9 2.7c4.3 0 7.7 3.4 7.7 7.6s-3.5 7.6-7.7 7.6-7.7-3.4-7.7-7.6 3.4-7.6 7.7-7.6z"
        />
      );


    default:
      return <path />;
  }
};

const SVGIcons = ({
  name = "",
  style = {},
  fill = "#000",
  viewBox = "",
  width = "100%",
  className = "",
  height = "100%"
}) => (
    <svg
      alt={name}
      width={width}
      style={style}
      height={height}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox || getViewBox(name)}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {getPath(name, { fill })}
    </svg>
  );

SVGIcons.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
  fill: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
}

export default SVGIcons;
