import { observable, action, decorate, runInAction } from "mobx"
import api from "services/api";

const NO_PAGE_TYPES = [
    "mer.EffectNode",
    "mer.AlternativeNode",
    "mer.IntroNode",
    "mer.TextNode",
    "product.ProductPage"
]

/**
 * Mobx store to load and control page content
 */
export class PageStore {
    /**
     * Page store loading state
     * @type {boolean}
     */
    loading = false;
    /**
    * Enquete loading state
     * @type {boolean}
     */
    loadingEnquete = false;
    /**
     * Page details object
     * @type {{}}
     */
    meta = {};
    /**
     * Page details object
     * @type {{}}
     */
    details = {};
    /**
     * Array of all pages used for routes
     * @type {Array}
     */
    routes = [];
    items = [];
    /**
     * Array of all pois
     * @type {Array}
     */
    pois = [];
    /**
     * Enquete form object
     * @type {{}}
     */
    products = [];
    /**
     * Enquete form object
     * @type {{}}
     */
    enqueteForm = {};
    /**
     * Error message
     * @type {string}
     */
    error = '';

    constructor() {
        this.api = api;
        this.getAllPages();
    }

    /**
     * Gets all pages and sets routes
     * @return {Promise<void>}
     */
    async getAllPages() {
        this.loading = true;
        try {
            let response = await this.api.Page.all();

            runInAction(() => {
                this.loading = false;
                this.items = response.items;
                this.routes = response.items.filter(route => !NO_PAGE_TYPES.includes(route.meta.type));
            })
        } catch (error) {
            runInAction(() => {
                this.error = error;
            })
        }
    }

    /**
     * Get page by id from the routes array
     * @param id Number
     * @return {boolean|Object}
     */
    getPageById(id) {
        if (!this.routes.length)
            return false;

        return this.routes.find(route => route.id === id);
    }

    /**
     * Method to check if path has a parent and include the slug in the url
     *
     * @param {Object} route
     * @param {String} url
     * @returns {String}
     */
    getPathWithParent = (route, url = '') => {
        if (route.meta.type === "home.HomePage") {
            return "/";
        }
        if (route.meta.parent) {
            const parentRoute = this.items.find(findRoute => findRoute.id === route.meta.parent.id)
            if (route.meta.type === 'mer.TextNode' || parentRoute.meta.type === 'mer.TextNode') {
                return this.getPathWithParent(parentRoute, url.includes('#') ? url : `#${route.meta.slug}`);
            } else if (parentRoute && parentRoute.meta.type !== "home.HomePage") {
                return this.getPathWithParent(parentRoute, `/${route.meta.slug}` + url);
            }
        }
        return `/${route.meta.slug}` + url;
    }

    /**
     * Get page details by id, sets details object
     * @param id Number
     * @return {Promise<T | never>}
     */
    async getPageDetails(id) {
        if (id === this.details.id)
            return;

        this.loading = true;
        try {
            let response = await this.api.Page.byId(id);
            runInAction(() => {
                this.details = response;
                this.loading = false;
            })
        } catch (error) {
            runInAction(() => {
                this.error = error;
            })
        }
    }
    /**
     * Get products by id
     * @return {Promise<void>}
     */
    async getProducts() {
        this.products = [];
        try {
            let response = await this.api.Page.getProducts(this.details.id);
            runInAction(() => {
                this.products = response.items;
                this.count = response.meta.total_count;
            })
        } catch (error) {
            runInAction(() => {
                this.error = error;
            })
        }
    }
}


decorate(PageStore, {
    loading: observable,
    details: observable,
    meta: observable,
    routes: observable,
    pois: observable,
    products: observable,
    enqueteForm: observable,
    error: observable,
    loadingEnquete: observable,

    getAllPages: action,
    getPageById: action,
    getPageDetails: action,
    getProducts: action,
    getPathWithParent: action
});

export default new PageStore();