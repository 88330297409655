import React ,{useState} from "react";
import PropTypes from "prop-types";
import { FormGroup, Input, Label, FormText, InputGroupAddon, InputGroup, Tooltip } from "reactstrap";
import ErrorFeedback from "components/Configurator/ErrorFeedback";


function FieldGroup({ label, name, children, onChange, error, help_text,tooltip_text, placeholder, addon, addonType = "append", ...inputProps }) {

    const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

    const inputComponents = <>
        <Input
            invalid={!!error}
            name={name}
            id={name}
            placeholder={placeholder}
            {...inputProps}
        >
            {children}
        </Input>
        {addon && <InputGroupAddon addonType={addonType}>{addon}</InputGroupAddon>}
        {tooltip_text && <InputGroupAddon id={'tooltip'+name} addonType={addonType}>?</InputGroupAddon>}
        {error && <ErrorFeedback error={error} />}
        {tooltip_text && <Tooltip trigger="hover" placement="bottom" isOpen={popoverOpen} target={'tooltip'+name} toggle={toggle}>
            {tooltip_text}
        </Tooltip>}
    </>;

    return <FormGroup>
        <Label for={name}>{label}</Label>
        {addon ? <InputGroup>{inputComponents}</InputGroup> : inputComponents}
        {help_text && <FormText>{help_text}</FormText>}
    </FormGroup>
}

FieldGroup.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    name: PropTypes.string,
    children: PropTypes.node,
    onChange: PropTypes.func,
    error: PropTypes.object,
    help_text: PropTypes.string,
    placeholder: PropTypes.string,
    addon: PropTypes.string,
    addonType: PropTypes.string
}

export default FieldGroup;