import { observable, action, runInAction, decorate } from 'mobx';
import api from 'services/api';

export class ContactStore {
    success = false;
    loading = false;
    error = '';

    constructor() {
        this.api = api;
    }

    sendForm = async (formData, page_id) => {
        let data = {
            form_data: formData,
            page: page_id
        };
        data.form_data = JSON.stringify(data.form_data, null, 2);
        try {
            await this.api.Contact.send(data);

            runInAction(() => {
                this.success = true;
                this.loading = false;
            });

        } catch (error) {
            runInAction(() => {
                this.success = false;
                this.error = error.response.data;
            });
        }
    };

}

decorate(ContactStore, {
    loading: observable,
    success: observable,
    error: observable,
    sendForm: action,
})

export default new ContactStore();
