import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import { Row, Container, Col, Input, FormGroup, CustomInput } from "reactstrap";
import { withRouter } from 'react-router';
import withPage from "../../components/withPage/withPage";
import Blocks from "../../components/Blocks/Blocks";
import SolutionCard from "../../components/Configurator/SolutionCard";
import Footer from "../../components/Footer/Footer";

/**
 * Productlist
 *
 * @description
 * Renders pois on the map
 */
class ProductList extends Component {
    static propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string
        }),
        match: PropTypes.shape({
            path: PropTypes.string
        }),
        PageStore: PropTypes.shape({
            products: MobxPropTypes.observableArray,
            getProducts: PropTypes.func,
            details: MobxPropTypes.observableObject
        })
    }

    state = {
        filteredProducts: null,
        filter: 'all',
        loading: false,
        sort: 'title',
        direction: 'asc'
    }


    filterProducts(e) {
        this.setState({ 'filter': e.target.id });
    }
    sortItems(e) {
        if (e.target.value) {
            let prefix = e.target.value.substr(0, e.target.value.indexOf('-'));
            let suffix = e.target.value.substr(e.target.value.indexOf('-') + 1);

            this.setState({ sort: prefix });
            this.setState({ direction: suffix });
        }
    }



    componentDidMount() {
        this.props.PageStore.getProducts();
    }

    render() {
        const { location, match, PageStore: { products, details } } = this.props;

        const selectedProduct = location.pathname.replace(match.path + '/', '');

        const filteredProducts = products
            .filter(product => (this.state.filter === 'all' ? product : product[this.state.filter] === true))

        return (
            <>
                <div className="pt-4">
                    {details.body &&
                        <Blocks blocks={details.body} />
                    }
                    <Container>

                        <Row className="mb-4">
                            <Col xs="12">
                                <h3>Filter maatregelen</h3>
                                <FormGroup check className="mr-4">
                                    <CustomInput onChange={(e) => this.filterProducts(e)} name="filterMeasure" type="radio" id="all" label="Alle maatregelen" defaultChecked />
                                </FormGroup>
                                {details.pagetype === 'waterberging' ? <>
                                    <FormGroup check className="mr-4">
                                        <CustomInput onChange={(e) => this.filterProducts(e)} name="filterMeasure" type="radio" id="in_garden" label="In de tuin" />
                                    </FormGroup>
                                    <FormGroup check className="mr-4">
                                        <CustomInput onChange={(e) => this.filterProducts(e)} name="filterMeasure" type="radio" id="on_roof" label="Op en onder het huis" />
                                    </FormGroup>
                                    <FormGroup check>
                                        <CustomInput onChange={(e) => this.filterProducts(e)} name="filterMeasure" type="radio" id="under_ground" label="In en onder de grond" />
                                    </FormGroup>
                                </> : <>
                                    <FormGroup check className="mr-4">
                                        <CustomInput onChange={(e) => this.filterProducts(e)} name="filterMeasure" type="radio" id="in_interior" label="Maatregelen met betrekking tot het interieur" />
                                    </FormGroup>
                                    <FormGroup check className="mr-4">
                                        <CustomInput onChange={(e) => this.filterProducts(e)} name="filterMeasure" type="radio" id="in_construction" label="Maatregelen met betrekking tot de constructie" />
                                    </FormGroup>
                                </>}
                            </Col>
                        </Row>
                        {products &&

                            <Row className="mb-4">
                                <Col >
                                    <h3>{filteredProducts.length === 1 ? '1 maatregel' : filteredProducts.length + ' maatregelen'}</h3>
                                </Col>
                                <Col className="col-auto text-right">
                                    <Input defaultValue="title-asc" onChange={(e) => this.sortItems(e)} type="select">
                                        <option value="">Sorteer...</option>
                                        <option value="price-desc">Prijs aflopend</option>
                                        <option value="price-asc">Prijs oplopend</option>
                                        <option value="title-asc">A-B-C</option>
                                        <option value="title-desc">Z-Y-X</option>
                                    </Input>
                                </Col>
                            </Row>

                        }
                        <Row>
                            {products && products.length && filteredProducts
                                .sort((a, b) => this.state.sort === 'price' ? (
                                    this.state.direction === 'asc' ? a[this.state.sort] - b[this.state.sort] : b[this.state.sort] - a[this.state.sort]
                                ) : (
                                    this.state.direction === 'asc' ? a[this.state.sort].localeCompare(b[this.state.sort]) : b[this.state.sort].localeCompare(a[this.state.sort])
                                ))
                                .map((product, index) => (
                                    <SolutionCard
                                        key={index}
                                        solution={product}
                                        open={product.meta.slug === selectedProduct}
                                    />

                                ))
                            }
                        </Row>
                    </Container>
                </div>
                <Footer
                    className='info-footer'
                    {...details.footer}
                />
            </>
        )
    }
}

export default withRouter(withPage(inject('PageStore')(observer(ProductList))));