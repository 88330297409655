import React, { Component } from "react";
import { Button, Form, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import serialize from "form-serialize";
import SimpleModal from "components/SimpleModal/SimpleModal";
import ContactFormField from "./ContactFormField";
/**
 * @class ContactForm
 * @extends {Component}
 * Shows the contactForm
 */
class ContactForm extends Component {
    static propTypes = {
        /**
         * Enquete id
         */
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        /**
         * Enquete form fields
         */
        form_fields: MobxPropTypes.observableArray,
        /**
         * Submit button text
         */
        submit_button_text: PropTypes.string,
        /**
         * Enquete store
         */
        contactStore: PropTypes.shape({
            /**
             * Add submission
             */
            sendForm: PropTypes.func,
            success: PropTypes.bool
        }),
        /**
         * Success modal title
         */
        thank_you_title: PropTypes.string,
        /**
         * Success modal text
         */
        thank_you_text: PropTypes.string
    };

    state = {
        open: false,
        btnDisabled: false
    };

    form = React.createRef();

    openModal = () => {
        return this.setState({ open: true });
    };

    submitForm = async (submitEvent) => {
        submitEvent.preventDefault();

        this.setState({ btnDisabled: true });

        const data = serialize(submitEvent.target, { hash: true });
        await this.props.contactStore.sendForm(data, this.props.id);
        if (this.props.contactStore.success) {
            this.openModal(true);
            this.form.current.reset();
            this.setState({ btnDisabled: false });
        }
        else {
            this.setState({ btnDisabled: false });
        }
    };

    render() {
        const { form_fields, submit_button_text, thank_you_title, thank_you_text, id } = this.props;
        return <>
            <Form innerRef={this.form} className="survey" onSubmit={this.submitForm}>

                {form_fields.map((field) => <ContactFormField key={field.id} enqueteId={id} {...field} />)}

                <FormGroup className="text-right">
                    <Button color="primary" disabled={this.state.btnDisabled}>{submit_button_text}</Button>
                </FormGroup>
            </Form>
            <SimpleModal
                closeModal={() => this.setState({ open: false })}
                open={this.state.open}
                title={thank_you_title}
            >{thank_you_text}</SimpleModal>
        </>;
    }
}

export default inject("contactStore")(observer(ContactForm));
