import React from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";

function Header({ title, content }) {
    return <Row className="justify-content-center mb-3 align-content-center">
        <Col sm={12} ><h1 className="h3 font-weight-bold mb-4">{title}</h1></Col>
        <Col sm={12} className="align-self-end"><p dangerouslySetInnerHTML={{__html: content}}></p></Col>
    </Row>;
}

Header.propTypes = {
    title: PropTypes.string,
    dynamicText: PropTypes.string,
    content: PropTypes.string
};

export default Header;