import React from 'react';
import {PropTypes} from "mobx-react";
import CollapseItem from "../../components/CollapseList/CollapseItem";

function CollapseList({list}) {

    const collapseItem = list.map((listItem, index) =>
        <div key={index} className="panel panel-default">
            <CollapseItem answer={listItem.answer} question={listItem.question}/>
        </div>
    );

    return (<>
            {collapseItem}
        </>
    );
}

CollapseList.propTypes = {
    list: PropTypes.observableArray
};

export default CollapseList;