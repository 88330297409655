import React from "react";
import PropTypes from "prop-types";

function PrevButton({ activeTab, toggle, buttonText }) {
    return <>
        {activeTab === '2' &&
            <button onClick={() => toggle('1')} className="btn btn-ghost text-primary">{buttonText}</button>
        }
        {activeTab === '3' &&
            <button onClick={() => toggle('2')} className="btn btn-ghost text-primary">{buttonText}</button>
        }
        {activeTab === '4' &&
            <button onClick={() => toggle('3')} className="btn btn-ghost text-primary">{buttonText}</button>
        }
        &nbsp;
    </>;
}

PrevButton.propTypes = {
    activeTab: PropTypes.string,
    toggle: PropTypes.func,
    buttonText: PropTypes.string
}

export default PrevButton;