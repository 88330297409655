import React from "react";
import {Col, Row} from "reactstrap";
import PropTypes from "prop-types";

function HouseProperty({label, image, children}) {
    return (
        <Col md={6} className="d-flex flex-column align-items-stretch">
            <div className="text-center border-bottom pb-3 mb-4 h-100">
                <h4 className="mb-0">{label}</h4>
                <img alt={label} className="img-fluid img-houseproperty mb-2" src={image}/>
                <Row className="justify-content-center">
                    <Col xs={9} lg={6}>
                        {children}
                    </Col>
                </Row>
            </div>
        </Col>
    );
}

HouseProperty.propTypes = {
    label: PropTypes.string,
    image: PropTypes.string,
    children: PropTypes.node
};

export default HouseProperty;