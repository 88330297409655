import React, { useState } from "react";
import { Col, Card, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { replaceParameterTags } from "utils/textHelpers";
import { ReactComponent as Energylabel } from "assets/img/energy-label.svg";
import { inject, observer } from "mobx-react";

function Waterlabel({ PageStore: { getPathWithParent }, head, intro, text, labels, label, hyperlink_moreinfo, hyperlink_measures, open = false, ...data }) {

    const [modal, setModal] = useState(open)

    const labelValues = ['A', 'B', 'C', 'D', 'E', 'F']

    const currentLabel = labelValues[parseInt(label - 1)]
    const currentLabelText = labels[parseInt(label - 1)]

    function toggleModal(e) {
        e.preventDefault();
        setModal(!modal);
    };

    return <Col xs={12} lg={6} className="mb-4">
        <Card className=" p-3 text-dark bg-light">
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="mb-0">{head}</h3>
                <a href="?" onClick={toggleModal} data-label={currentLabel} className="waterlabel">{currentLabel}</a>
            </div>

            <p dangerouslySetInnerHTML={{ __html: replaceParameterTags(intro, { label: currentLabel }) }} />

            <div className="text-right">
                <Button onClick={toggleModal} color="primary">Meer informatie</Button>
            </div>
        </Card>
        <Modal size="md" isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Waarom Label {currentLabel} voor {head}?</ModalHeader>
            <ModalBody>
                <div className="d-flex mb-4">
                    <div className="flex-grow-1">
                        <div data-label={currentLabel} className="energylabel-svg mb-4 mb-md-0">
                            <Energylabel />
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: currentLabelText }} ></p>
                        <p dangerouslySetInnerHTML={{ __html: text }} ></p>
                    </div>
                </div>



                <div className="d-flex justify-content-between">
                    {hyperlink_measures &&
                        <Link to={getPathWithParent(hyperlink_measures)} className="btn btn-secondary">Maatregelen</Link>
                    }
                    {hyperlink_moreinfo &&
                        <Link to={getPathWithParent(hyperlink_moreinfo)} className="btn btn-primary">Meer informatie</Link>
                    }


                </div>
            </ModalBody>
        </Modal>
    </Col>;
}

export default inject('PageStore')(observer(Waterlabel));