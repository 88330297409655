import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { Container } from "reactstrap";
import withPage from "../../components/withPage/withPage";
import Blocks from "../../components/Blocks/Blocks";
import Footer from "../../components/Footer/Footer";


export class InfoPage extends Component {
    static propTypes = {
        PageStore: PropTypes.shape({
            details: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                title: PropTypes.string,
                body: PropTypes.object,
                footer: PropTypes.object
            })
        })
    };

    render() {

        const { PageStore: { details } } = this.props;

        return (
            details.body &&
            <>
                <main>
                    <div className="pt-4">
                        {details.title &&

                            <Container>
                                <h1>{details.title}</h1>
                            </Container>

                        }
                        <Blocks blocks={details.body} />
                    </div>
                </main>

                <Footer
                    className='info-footer'
                    {...details.footer}
                />

            </>

        );
    }
}

export default withRouter(withPage((observer(InfoPage))))