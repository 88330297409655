import React, { Component } from 'react'
import PropTypes from "prop-types";
import LazyLoad from 'react-lazyload';
import { Col, Container, Row } from "reactstrap";
import HeadingBlock from "../../../components/Blocks/Heading/Heading";
import ParagraphBlock from "../../../components/Blocks/Paragraph/Paragraph";
import FeaturesBlock from "../../../components/Blocks/Features/Features";
import HtmlBlock from "../../../components/Blocks/Html/Html";
import ImageBlock from "../../../components/Blocks/Image/Image";
import DocumentBlock from "../../../components/Blocks/Document/Document";
import JumbotronBlock from "../../../components/Blocks/Jumbotron/Jumbotron";
import TwoColumnsBlock from "../../../components/Blocks/TwoColumns/TwoColumns";
import CallToActionBlock from "../../../components/Blocks/CallToAction/CallToAction";
import AsideParagraphBlock from "../../../components/Blocks/AsideParagraph/AsideParagraph";
import CollapseBlock from "../../../components/Blocks/CollapseBlock/Collapseblock";

import CarouselBlock from "../../../components/Blocks/Carousel/Carousel";

import CardsBlock from "../../../components/Blocks/Cards/Cards";
import TimelineBlock from "../../../components/Blocks/Timeline/Timeline";

import config from "../../../config.json"


export class BasicBlock extends Component {

    blockTheme() {

        if (!this.props.data.block_theme) {
            return '';
        } else if (this.props.data.block_theme.select_theme === '') {
            return "theme-" + config.blocks.defaults.defaultTheme;
        } else {
            return "theme-" + this.props.data.block_theme.select_theme;
        }

    }

    blockMargin() {
        if (!this.props.data.margin_bottom) {
            return '';
        } else if (this.props.data.margin_bottom.select_margin_bottom === '') {
            return config.blocks.defaults.defaultMargin;
        } else {
            return this.props.data.margin_bottom.select_margin_bottom;
        }
    }

    fullWidthRows() {
        if (config.blocks.defaults.fullWidthRows === true) {
            return 'container-fluid px-0 ';
        } else {
            return 'container px-0 ';
        }
    }

    blockAlign() {

        if (!this.props.data.text_alignment) {
            return '';
        } else if (this.props.data.text_alignment.select_text_alignment === '') {
            return config.blocks.defaults.defaultTextAlign;
        } else {
            return this.props.data.text_alignment.select_text_alignment;
        }
    };

    blockSwitcher() {

        switch (this.props.type) {
            case "heading_block":
                return <HeadingBlock key={this.props.id} data={this.props.data} />;
            case "paragraph_block":
                return <ParagraphBlock key={this.props.id} data={this.props.data} />;
            case "html_block":
                return <HtmlBlock key={this.props.id} data={this.props.data} />;
            case "image_block":
                return <ImageBlock key={this.props.id} data={this.props.data} />;
            case "document_block":
                return <DocumentBlock key={this.props.id} data={this.props.data} />;
            case "jumbotron_block":
                return <JumbotronBlock key={this.props.id} data={this.props.data} />;
            case "two_columns_block":
                return <TwoColumnsBlock key={this.props.id} data={this.props.data} />;
            case "cta_block":
                return <CallToActionBlock key={this.props.id} data={this.props.data} />;
            case "cards_block":
                return <CardsBlock key={this.props.id} data={this.props.data} />;
            case "timeline_block":
                return <TimelineBlock key={this.props.id} data={this.props.data} />;
            case "aside_paragraph_block":
                return <AsideParagraphBlock key={this.props.id} data={this.props.data} />;
            case "carousel_block":
                return <CarouselBlock key={this.props.id} data={this.props.data} items={this.props.data.slides} />;
            case "collapsible_block":
                return <CollapseBlock key={this.props.id} items={this.props.data.items} />;
            case "features_block":
                return <FeaturesBlock key={this.props.id} data={this.props.data} />;
            default:
                return null;
        }
    };


    render() {

        const lazyloadDisabled = (this.props.disableLazyload) ? true : false;
        const nestedBlock = (this.props.nestedBlock) ? ' pt-0 ' : ' pt-4 ';

        return (
            <>
                <div className={this.fullWidthRows() + " " + this.props.type}>
                    <Row className={this.blockTheme() + " " + this.blockAlign() + nestedBlock + " no-gutters"}>
                        <Col sm={12} className={this.blockMargin() + " w-100"}>
                            <Container>

                                {(lazyloadDisabled) ? (
                                    <>
                                        {this.blockSwitcher()}
                                    </>
                                ) : (
                                        <LazyLoad>
                                            {this.blockSwitcher()}
                                        </LazyLoad>
                                    )}


                            </Container>
                        </Col>
                    </Row>
                </div>

            </>
        );
    }
}


BasicBlock.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.object,
    disableLazyload: PropTypes.bool,
    nestedBlock: PropTypes.any
};

export default BasicBlock
