import React from "react";
import Home from "../../scenes/Home/Home";
import InfoPage from "../../scenes/Info/InfoPage";
import ProductList from "../../scenes/ProductList/ProductList";
import NotFound from "../../components/NotFound/NotFound";
import SearchPage from "../../scenes/SearchPage/SearchPage";
import ConfiguratorPage from "../../scenes/Configurator/ConfiguratorPage";
import ProductPage from "../../scenes/ProductPage/ProductPage";
import ContactPage from "../../scenes/Contact/ContactPage";

/**
 * Method to return the correct component given a wagtail route
 *
 * @param route object with wagtail route data
 * @returns {Node} Loader or Page component
 */
export const getPageComponentFromType = (route) => {
    let page;

    switch (route.meta.type) {
        case 'home.HomePage':
            page = <Home {...route} />;
            break;
        case 'info.InfoPage':
            page = <InfoPage {...route} />;
            break;
        case 'list.ListPage':
            page = <ProductList {...route} />;
            break;
        case 'search.SearchPage':
            page = <SearchPage {...route} />
            break;
        case 'configurator.ConfiguratorPage':
            page = <ConfiguratorPage {...route} />
            break;
        case 'product.ProductPage':
            page = <ProductPage {...route} />
            break;
        case 'contact.ContactPage':
            page = <ContactPage {...route} />
            break;
        default:
            page = <NotFound />;
            break;
    }

    return page;
};