import React from 'react'
import PropTypes from "prop-types";
import { FormFeedback } from 'reactstrap';


export default function ErrorFeedback({ error }) {
    let message = error.message;
    switch (error.type) {
        case "required":
            message = error.ref.name + " is verplicht."
            break;
        case "pattern":
            message = `Ongeldige ${error.ref.name} ingevuld.`;
            break;
        default:
            break;
    }

    return <FormFeedback>{message}</FormFeedback>
}

ErrorFeedback.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string,
        type: PropTypes.string,
        ref: PropTypes.shape({
            name: PropTypes.string
        })
    })
}