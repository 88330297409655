import React from 'react';
import PropTypes from "prop-types";

function Html(content) {
    return (
        <div className="rte" dangerouslySetInnerHTML={{__html: content.data}}>
        </div>

    );
}

Html.propTypes = {
    content: PropTypes.shape({
        data: PropTypes.string
    })
};
export default Html;