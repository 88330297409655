import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import withPage from "components/withPage/withPage";
import { observer, PropTypes as MobxPropTypes } from "mobx-react";
import { Container } from "reactstrap";
import Footer from "components/Footer/Footer";
import ContactForm from "components/ContactForm/ContactForm";

const ContactPage = function ({ PageStore: { details } }) {

    return (
        <>
            <main>
                <Container className="py-4">
                    <h1>{details.title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: details.intro }} />
                    <ContactForm {...details} />
                </Container>
            </main>

            <Footer
                className='info-footer'
                {...details.footer}
            />

        </>
    );
};

ContactPage.propTypes = {
    PageStore: PropTypes.shape({
        details: PropTypes.shape({
            title: PropTypes.string,
            intro: PropTypes.string,
            form_fields: MobxPropTypes.observableArray,
            footer: MobxPropTypes.observableObject
        })
    })
}


export default withRouter(withPage((observer(ContactPage))))
